import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TH3CellFeatureProps } from '../../pages/bicycle-observatory/models/origin-destination';

function H3CellTooltip({
  object: {
    properties: { name, flows, isExternal },
  },
}: {
  object: GeoJSON.Feature<GeoJSON.Polygon, TH3CellFeatureProps>;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography fontWeight={600} variant="body2">
          {name}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          {t('cycling-insights.usage.origin_destination.incoming_flows', {
            count: flows?.to,
          })}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2">
          {t('cycling-insights.usage.origin_destination.outgoing_flows', {
            count: flows?.from,
          })}
        </Typography>
      </Box>
      {!isExternal && (
        <Box>
          <Typography variant="body2">
            {t('cycling-insights.usage.origin_destination.internal_flows', {
              count: flows?.internal,
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default H3CellTooltip;
